 
// @mui material components
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "devComponents/Navbars/PublicNavbar";

// Author page sections 
import Contact from "./ConsultationForm"; 
 

// Images
import bgImage from "assets/images/LandingPage/Deal.jpg";
import bgImage2 from "assets/images/LandingPage/Consultation2.jpg";

import MKTypography from "components/MKTypography";

function index() {
  const getBackgroundImage = () => {
    // flip a coin
    const coin = Math.floor(Math.random() * 2);
    // return the image
    return coin === 0 ? bgImage : bgImage2;
  }
  return (
    <>
      <DefaultNavbar/>
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${getBackgroundImage()})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
             <Container>
          <Grid container mb={3} item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="dark"
              textAlign="center"

              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              The devHouse Consultation Program
            </MKTypography>
            <MKTypography
              variant="h5"
              color="dark"
              textAlign="center"

              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Congratulations for making it this far!
            </MKTypography>
            <MKTypography
              variant="body1"
              color="dark"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={2}
            >
              The devHouse Consultation Program is, first and foremost, an effort to give back to the community by making our knowledge available through a friendly consultation session.
              This also serves as a low-commitment way to get your questions answered.
              We are happy to answer any questions you have about our products and services as well as about tech in general.
              You set your own time and its FREE!
            </MKTypography>
          </Grid>
        </Container>
          
        <Contact /> 
        </Card>
      </MKBox>
    </>
  );
}

export default index;
