
const imagesPrefix =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/material-design-system/presentation/sections";

export default [
  {
    title: "1. Book a Consultation Session",
    description: "It's free and always will be.",
    items: [
      {
        text: "Explain your needs and business cycle to us. Take your time, be detailed.",
      },
      {
        text: "Next, we Formulate a custom tailored solution, designed specifically to meet your unique requirments.",
      },
    ],
  },
  {
    title: "2. Iron Out the Details",
    description: "Confirm the solution's requirements, timeline, and budget.",
    items: [
      {
        text: "Agree on a set of acceptance criteria for the solution.",
      },
      {
        text: "Agree on a timeline that may include milestones depending on the solution's size.",
      },
      {
        text: "Agree on a budget for the solution. (Don't worry, we'll be flexible.)",
      },
    ],
  },
  {
    title: "3. Pay a Deposit",
    description: "Not the entire amount, just the first payment.",
    items: [
      {
        text: "Invest an agreed upon percentage % of the solution's budget as a deposit.",
      },
      {
        text: "As soon as the deposit is received, we'll start working on the solution. Your in safe hands now!",
      },
      {
        text: "All payments are made secure with PAYPAL.",
      },
    ],
  },
  {
    title: "4. Project Launch",
    description: "The moment we've all been waiting for.",
    items: [
      {
        text: "After the development phase is over we start the deployment phase.",
      },
      {
        text: "This is where we upload all the seperate parts of your integrated solution to the cloud.",
      },
      {
        text: "Typically, the major parts of any solution that need to be hosted on the cloud are the Client (User Interface or frontend), the Server (Backend), and the database(s).",
      },
      {
        text: "We also help you in finding, aquiring, and seting up a domain name if needed.",
      },
    ],
  },
  {
    title: "5. Finalization",
    description: "Take your time, test it out first.",
    items: [
      {
        text: "After deployment we give you an amount of time to try the finished product and test it out for yourself.",
      },
      {
        text: "After that you give us your feedback about any changes or revisions that you would like to be done and we will handle it.",
      },
      {
        text: "Once you are 100% satisfied with the solution you may pay the remainder of the solution's total budget which was agreed upon.",
      },
    ],
  },
];
