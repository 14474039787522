import React, { useEffect, useState } from 'react'
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import macbook from "assets/images/macbook.png";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { Navigate, useNavigate } from 'react-router-dom';

const Project = ({ Project }) => {
    const [ImgSrc, setImgSrc] = useState("");
    let storage = getStorage();
    let navigate = useNavigate();
    useEffect(() => {
        GetImage();
    }, []);
    const GetImage = async () => {
        let imageRef = ref(storage, 'PastProjects/' + Project.id + '/CoverImage')
        let image = await getDownloadURL(imageRef);
        setImgSrc(image);
    }
    return (
        <MKBox component="section" pt={6} my={6}>
          <Container>
            <Grid container alignItems="center">
              <Grid item sx={12} md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
                <MKTypography variant="h4">{Project.title}</MKTypography>
                <MKTypography variant="body2" color="text" mb={3}>
                  {Project.description}
                </MKTypography>
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    {Project.link != "" && <a href={Project.link} target="_blank" style={{
                        color: 'lightGreen',
                        border: '1px solid black',
                        borderRadius: '5px',
                        padding: '0.5em',
                        fontSize: '1em',
                        backgroundColor: 'black'

                    }}>Visit Live</a>}
                  </Grid>
                  <Grid item xs={4}>
                    <MKButton variant="standard" color="info" sx={{ height: "100%" }} onClick={() => navigate("/PastProjects/Details", { state:{ project: Project }})}>
                      Learn more
                    </MKButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
                <MKBox position="relative">
                  <MKBox component="img" src={ImgSrc} alt={macbook} maxWidth="20em" maxHeight="20em" />
                </MKBox>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      );
  
}

export default Project