
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { getFirestore, addDoc, doc, collection, serverTimestamp, onSnapshot, increment, setDoc } from "firebase/firestore";

// import useState and useEffect to handle the form state and submission
import { useState, useEffect } from "react";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Swal from "sweetalert2";

function Contact() {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [secondaryContact, setSecondaryContact] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [meetingPlatform, setMeetingPlatform] = useState("");
  const [meetingTime, setMeetingTime] = useState("");
  const [meetingDate, setMeetingDate] = useState("");

  const db = getFirestore()

  const handleSubmit = async () => {
    if (name === "" || email === "" || secondaryContact === "" || subject === "" || message === ""
      || meetingPlatform === "" || meetingTime === "" || meetingDate === "") {
      Swal.fire({
        title: "Error",
        text: "Please fill all the fields",
        icon: "error",
        confirmButtonText: "OK"
      });
      return;
    }

    if (!email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)) {
      Swal.fire({
        title: "Error",
        text: "Please enter a valid email",
        icon: "error",
        confirmButtonText: "OK"
      });
      return;
    }

    // get the user's timezone
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // set the document in the database
    var collectionRef = collection(db, "consultationProgram");
    var res = await addDoc(collectionRef, {
      name,
      email,
      secondaryContact,
      subject,
      message,
      meetingPlatform,
      meetingTime,
      meetingDate,
      createdAt: serverTimestamp(),
      timezone
    })
    console.log(res)
    // collectionRef.update({count: increment(1)});
    const docRef = doc(db, "consultationProgram", "consultationProgramData");
    var res2 = await setDoc(docRef, {
      count: increment(1)
    }, {merge: true});
    console.log(res2)
      
    // reset the form
    document.getElementById("consultationForm").reset();
    Swal.fire({
      title: "Success",
      text: "Your request has been submitted. Your Request ID is " + res.id + ". We will get contact you at the earliest.",
      icon: "success",
      confirmButtonText: "OK"
    });

  }


  return (
    <MKBox component="section" py={{ xs: 0, lg: 6 }}>
      <Container>
        <Grid container item>
          <MKBox
            width="100%"
            bgColor="white"
            borderRadius="xl"
            shadow="xl"
            mb={6}
            sx={{ overflow: "hidden" }}
          >
            <MKBox id="consultationForm" component="form" p={2} method="post">
              <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                <MKTypography variant="body1" color="text" mb={2}>
                  Fill out the form below and click send to complete the request.
                </MKTypography>
              </MKBox>
              <MKBox pt={0.5} pb={3} px={3}>
                <Grid container>
                  <Grid item xs={12} pr={1} mb={6}>
                    <MKInput
                      variant="standard"
                      label="My name is"
                      placeholder="Full Name"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      onChange={(e) => setName(e.target.value)}
                      id="name"
                    />
                  </Grid>
                  <Grid item xs={12} pr={1} mb={6}>
                    <MKInput
                      id="email"
                      variant="standard"
                      label="I'm looking for"
                      placeholder="Subject"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} pr={1} mb={6}>
                    <MKInput
                      id="secondaryContact"
                      variant="standard"
                      label="Describe your subject in detail"
                      placeholder="Describe what you need, or the nature of your request"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      multiline
                      rows={6}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} pr={1} mb={6}>
                    <MKInput
                      id="meetingPlatform"
                      variant="standard"
                      label="My primary email is"
                      placeholder="example@something.com"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} pr={1} mb={6}>
                    <MKInput
                      id="meetingTime"
                      variant="standard"
                      label="My secondary contact info is"
                      placeholder="Any other contact info that can help us reach you, to confirm the meeting/call. (e.g. phone number, IMessage, FaceBook etc.)"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      rows={4}
                      onChange={(e) => setSecondaryContact(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} pr={1} mb={6}>
                    <MKInput
                      id="meetingDate"
                      variant="standard"
                      label="I'd like to have the meeting on"
                      placeholder="Zoom, Google Meet, Skype, etc."
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      onChange={(e) => setMeetingPlatform(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} pr={1} mb={6}>
                    <MKInput
                      id="meetingTime"
                      type="date"
                      variant="standard"
                      label="Im Free to schedule a meeting on this day"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      onChange={(e) => setMeetingDate(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} pr={1} mb={6}>
                    <MKInput
                      id="meetingTime"
                      type="time"
                      variant="standard"
                      label="at this time"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      onChange={(e) => setMeetingTime(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  md={6}
                  justifyContent="flex-end"
                  textAlign="right"
                  ml="auto"
                >
                  <MKButton variant="gradient" color="dark"
                    onClick={handleSubmit}>
                    Send Request
                  </MKButton>
                </Grid>
              </MKBox>
            </MKBox>

          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Contact;
