/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import PrincipalCard from "../components/PrincipalCard";
function TheThreePrincipals() {
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            {/* <DefaultCounterCard
              count={70}
              suffix="+"
              title="Coded Elements"
              description="From buttons, to inputs, navbars, alerts or cards, you are covered"
            /> */}
            <PrincipalCard
            // bigTitle={"Communication"}
            title={"Communication is Key"}
            description={"In order to develop the best possible solution for our clients, we make an effort to deeply understand their needs and goals."}
            />
              
          </Grid>
          <Grid item xs={12} md={4} display="flex">
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
            <PrincipalCard
            // bigTitle={"Hassle Free"}
            title={"100% Hassle Free"}
            description={"We take care of all the technical stuff, so you can focus on your business. From development to deployment to ongoing maintenance, your project is 100% our responsibility."}
            />
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <PrincipalCard
            // bigTitle={"Long relationship"}
            title={"We're in it for the long run!"}
            description={"Our job doesn't end with development or deployment. We also provide ongoing support and maintenance to ensure that your project is up and running smoothly at all times."}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default TheThreePrincipals;
