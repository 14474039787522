import React, { useState, useEffect } from 'react'
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import DefaultNavbar from "devComponents/Navbars/PublicNavbar";
import bgImage from "assets/images/LandingPage/PastProjects.jpg";
import MKTypography from "components/MKTypography";
import { useLocation } from 'react-router-dom';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { Navigate, useNavigate } from 'react-router-dom';
import Feature from './Feature';

const Index = () => {
    let location = useLocation();
    console.log(location);
    const [ImgSrc, setImgSrc] = useState("");
    const [Project, setProject] = useState(location.state.project);
    let storage = getStorage();
    let navigate = useNavigate();
    useEffect(() => {
        GetImage();
    }, []);
    const GetImage = async () => {
        let imageRef = ref(storage, 'PastProjects/' + Project.id + '/CoverImage')
        let image = await getDownloadURL(imageRef);
        setImgSrc(image);
    }
    return (
        <>
            <DefaultNavbar />
            <MKBox bgColor="white">
                <MKBox
                    minHeight="25rem"
                    width="100%"
                    sx={{
                        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                            `${linearGradient(
                                rgba(gradients.dark.main, 0.8),
                                rgba(gradients.dark.state, 0.8)
                            )}, url(${ImgSrc})`,
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        display: "grid",
                        placeItems: "center",
                    }}
                />
                <Card
                    sx={{
                        p: 2,
                        mx: { xs: 2, lg: 3 },
                        mt: -8,
                        mb: 4,
                        backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
                        backdropFilter: "saturate(200%) blur(30px)",
                        boxShadow: ({ boxShadows: { xxl } }) => xxl,
                    }}
                >
                    <Container>
                        <Grid container mb={3} item xs={12} lg={5} md={5} justifyContent="center" mx="auto">
                            <MKTypography
                                variant="h1"
                                color="dark"
                                textAlign="center"

                                mb={1}
                                sx={({ breakpoints, typography: { size } }) => ({
                                    [breakpoints.down("md")]: {
                                        fontSize: size["3xl"],
                                    },
                                })}
                            >
                                {Project.title}
                            </MKTypography>
                            <MKTypography
                                variant="h5"
                                color="dark"
                                textAlign="center"

                                mb={1}
                                sx={({ breakpoints, typography: { size } }) => ({
                                    [breakpoints.down("md")]: {
                                        fontSize: size["3xl"],
                                    },
                                })}
                            >
                                {Project.description}
                                
                            </MKTypography>
                            {location.state.project.link != "" && 
                            <a href={location.state.project.link} target="_blank" style={{
                                    color: 'lightGreen',
                                    border: '1px solid black',
                                    borderRadius: '5px',
                                    padding: '0.5em',
                                    fontSize: '1em',
                                    backgroundColor: 'black'

                                }}>Visit Live</a>}
                        </Grid>
                        
                    </Container>
                     {
                        Project.features.map((feature =>{
                            return (
                                <Feature Project={Project} Feature={feature} />
                            )
                        }))
                     }
                </Card>
            </MKBox>
        </>
    );
}

export default Index; 