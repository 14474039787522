 
// @mui material components
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "devComponents/Navbars/PublicNavbar";

// Author page sections 
import Contact from "./JobApplication"; 



// Images
import bgImage from "assets/images/LandingPage/TechProfit.jpg";
import MKTypography from "components/MKTypography";

function index() {
  return (
    <>
      <DefaultNavbar/>
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
             <Container>
          <Grid container mb={3} item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="dark"
              textAlign="center"

              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Careers at devHouse
            </MKTypography>
            <MKTypography
              variant="h5"
              color="dark"
              textAlign="center"

              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Congratulations for making it this far!
            </MKTypography>
            <MKTypography
              variant="body1"
              color="dark"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={2}
            >
              Here at devHouse, we are always looking for talented people to join our team! If you are interested in joining our team, please fill out the form below. 
            </MKTypography>
          </Grid>
        </Container>
          
        <Contact /> 
        </Card>
      </MKBox>
    </>
  );
}

export default index;
