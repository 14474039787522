import React, { useEffect, useState } from 'react'
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import macbook from "assets/images/macbook.png";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
const Feature = ({Feature, Project}) => {
    const [ImgSrc, setImgSrc] = useState(false);
    let storage = getStorage();
    useEffect(() => {
        GetImage();
    }, []);
    const GetImage = async () => {
        let imageRef = ref(storage, 'PastProjects/' + Project.id + '/features/' + Feature.name)
        let image = await getDownloadURL(imageRef);
        setImgSrc(image);
    }

  return (
     <MKBox component="section" pt={6} my={6}>
          <Container>
            <Grid container alignItems="center">
              <Grid item sx={12} md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
                <MKTypography variant="h4">{Feature.name}</MKTypography>
                <MKTypography variant="body2" color="text" mb={3}>
                  {Feature.description}
                </MKTypography>
              
              </Grid>
              <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
                <MKBox position="relative">
                  <MKBox component="img" src={ImgSrc} alt={macbook} maxWidth="20em" maxHeight="20em" />
                </MKBox>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
  )
}

export default Feature