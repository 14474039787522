// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes
import routes from "../../../Routes/publicRoutes";

import Logo from "assets/images/logos/logo-05_Cropped.png";

function index() {
  return (
    <MKBox variant="gradient" bgColor="dark" shadow="sm" py={0.25}>
      <DefaultNavbar
        routes={routes}
        Logo={Logo}
        action={{
          type: "internal",
          route: "/ConsultationProgram",
          label: "Start Now",
          color: "white",
        }}
        brand={"devHouse, by HB-TEC"}
        transparent
        relative
        light
        center
        sticky={true}
      />
    </MKBox>
  );
}

export default index;