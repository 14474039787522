
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 React examples
import PublicNavbar from "../../../devComponents/Navbars/PublicNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Presentation page sections
import TheThreePrincipals from "./sections/TheThreePrincipals";
import Information from "./sections/Information";
import DesignBlocks from "./sections/DesignBlocks";
import Pages from "pages/Presentation/sections/Pages"; 
import Download from "./sections/Download";

// Presentation page components
 
import footerRoutes from "footer.routes";
import { useNavigate } from "react-router-dom";

// Images
// import bgImage from "assets/images/bg-presentation.jpg";
import bgImage from "assets/images/LandingPage/RedLedKeyboard.jpg";
import MKButton from "components/MKButton";

function Presentation() {
  let navigate = useNavigate();
  return (
    <>
      <PublicNavbar />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Welcome to devHouse
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              The one stop shop for all your software development and tech needs.
            </MKTypography>
            <MKButton 
              variant="outlined"
              color="white"
              mt={4}
              mx="auto"
              circular={true}
              sx={{
                marginTop: "1.5em",
                width: "100%",
                maxWidth: "300px",
                fontSize: "1rem",
                fontWeight: "bold", 
                border: "1px solid white",  
                // "&:hover": {
                //   backgroundColor: "black",
                //   color: "white",
                // },
              }}
              onClick={() => navigate("/ConsultationProgram")}
            >
              BOOK FREE CONSULTATION SESSION
            </MKButton>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <TheThreePrincipals />
        <Information />
        <DesignBlocks />
         
        
        <Download />
        
      </Card>
      {/* <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox> */}
    </>
  );
}

export default Presentation;
